/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { BsCheck } from 'react-icons/bs';

interface CheckboxProps {
  className?: string;
  children?: ReactNode;
  id: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string;
  value?: string | number | boolean;
  checked?: boolean;
  icon?: ReactNode;
  uncheckedIcon?: ReactNode;
  top?: boolean;
  fullWidth?: boolean;
  [key: string]: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  children,
  id,
  disabled,
  readOnly,
  error,
  value,
  checked,
  icon,
  uncheckedIcon,
  top,
  fullWidth,
  ...rest
}) => {
  const pointer = disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <div
      className={clsx(
        'group flex flex-col space-y-2',
        fullWidth ? 'w-full' : 'max-w-max',
        readOnly && 'pointer-events-none',
      )}
    >
      <label
        className={clsx(
          'flex gap-2',
          fullWidth && 'w-full',
          !readOnly && pointer,
          top ? 'items-start' : 'items-center',
          className,
        )}
        htmlFor={id?.toString()}
        onKeyPress={() => undefined}
        onClick={(event) => event.stopPropagation()}
      >
        <input
          type="checkbox"
          className="sr-only invisible"
          id={id?.toString()}
          disabled={disabled}
          name={id?.toString()}
          value={value?.toString()}
          readOnly={readOnly}
          {...rest}
          defaultChecked={checked}
        />
        <div
          className={clsx(
            'flex h-5 min-h-5 w-5 min-w-5 items-center justify-center rounded border border-solid border-gray-500 transition-all',
            !disabled &&
              !error &&
              'cursor-pointer group-hover:border-primary-500 group-hover:bg-primary-200',
            readOnly ? 'cursor-default' : pointer,
            checked && !disabled ? '!border-primary-400 bg-primary-500' : 'bg-white/80',
            error && !disabled && 'group-hover:border-danger-500 group-hover:bg-danger-300',
            checked && error && !disabled && '!border-danger-400 bg-danger-500',
            disabled && 'cursor-not-allowed bg-gray-400',
          )}
        >
          {checked ? icon || <BsCheck className="text-lg text-white" /> : uncheckedIcon}
        </div>
        {children}
      </label>
      {error && <p className="font-secondary text-xs text-danger-500">{error}</p>}
    </div>
  );
};

Checkbox.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  error: undefined,
  value: '',
  top: false,
  checked: false,
  fullWidth: true,
  icon: null,
  uncheckedIcon: null,
  readOnly: false,
};

export default Checkbox;
